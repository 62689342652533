import React, { useState, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import './ControlActions.css';
import './../ViewOptions/ViewOptions.css';
import Signal from '../../../images/signal-01.svg';
import Infinity from '../../../images/infinity.svg';
import PlayCircle from '../../../images/play-circle.svg';
import StopCircle from '../../../images/stop-circle.svg';
import ClearAlarm from '../../../images/clear-alarm.svg';
import Refresh from '../../../images/refresh-ccw-01.svg';
import Mode from '../../../images/Mode.svg';
import Percent from '../../../images/percent.svg';
import Monitor from '../../../images/monitor.svg';
import Arrowright from '../../../images/chevron-right.svg';
import StartIcon from '../../../images/featured-icon.svg';
import ClearAlarms from '../../../images/clear-alarms.svg';
import ResetIcons from '../../../images/reset-icon.svg';
import Shutdown from '../../../images/shutdown.svg';
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks';
import SuccessfullToast from './../../common/toast/SuccessfullToast';
import ErrorToast from './../../common/toast/ErrorToast';
import { Switch } from '@headlessui/react';
import '../../common/ViewOptions/ViewOptions.css';
import Modal from '../Modal/Modal';
import {
  executeWellControlAction,
  enableWell,
  disableWell,
  setPreviousControlMode,
  clearShowMessageToast,
} from '../../asset/AssetDataSlice';

// These numbers map to the value expected by well control on the backend.
export enum WellControlAction {
  Start = 1,
  Stop = 2,
  Idle = 3,
  ClearAlarms = 4,
  ConstantRunMode = 5,
  PocMode = 6,
  PercentTimeMode = 7,
  Scan = 8,
  SetClock = 9,
  PowerShutdown = 10,
  UpdatePocData = 11,
  FastScan = 12,
  SurfaceMode = 13,
  VfdMode = 14,
  PipControl = 15,
  Download = 16,
  Upload = 17,
  PlungerLift5Min = 18,
  ScheduleMode = 19,
  ScanMasterAndSlaves = 20,
  EnablePid = 21,
  DisablePid = 22,
  FixedSpeedManual = 23,
  FixedSpeedTimer = 24,
  FixedSpeedPumpOff = 25,
  VariableSpeedPumpFillage = 26,
  VariableSpeedManual = 27,
  VariableSpeedPumpOff = 28,
  FixedSpeedPumpFillage = 29,
}

export enum NonTransactionWellControlAction {
  Enable = 1,
  Disable = 2,
  ScanContinuous = 3,
}

enum WellControlActionNestedListType {
  Mode = 1,
}

export enum ItemType {
  WellControlAction,
  NonTransactionWellControlAction,
  WellControlActionNestedList,
}

export interface ControlActionUIItem {
  itemType: ItemType;
  controlActionType: WellControlAction | NonTransactionWellControlAction | WellControlActionNestedListType;
  // This property is utilized when needing nested items
  nestedControlActions?: ControlActionUIItem[];
  label: string;
  icon?: string;
  hasBottomBorder?: boolean;
  navicon?: string | undefined;
}

export interface ActionsToExclude {
  itemType: ItemType;
  controlActionType: number;
}
interface ControlActionsProps {
  label: string;
  supportedControlActionIds: number[];
  buttonContent: React.ReactNode;
  isWellEnabled: boolean;
  assetId: string;
  actionsToExclude: ActionsToExclude[];
  canConfigWell: boolean;
  modeOptionViewPosition: 'left' | 'right';
}

const modeControlActions: ControlActionUIItem[] = [
  {
    itemType: ItemType.WellControlAction,
    controlActionType: WellControlAction.ConstantRunMode,
    label: 'Constant run',
    icon: Infinity,
    navicon: undefined,
  },
  {
    itemType: ItemType.WellControlAction,
    controlActionType: WellControlAction.PercentTimeMode,
    label: 'Timer',
    icon: Percent,
    navicon: undefined,
  },
  {
    itemType: ItemType.WellControlAction,
    controlActionType: WellControlAction.PocMode,
    label: 'POC',
    icon: Monitor,
    navicon: undefined,
  },
];

const allControlActions: ControlActionUIItem[] = [
  {
    itemType: ItemType.WellControlAction,
    controlActionType: WellControlAction.Scan,
    label: 'Scan',
    icon: Signal,
    hasBottomBorder: true,
    navicon: undefined,
  },
  {
    itemType: ItemType.NonTransactionWellControlAction,
    controlActionType: NonTransactionWellControlAction.ScanContinuous,
    label: 'Scan continuously',
    icon: Infinity,
    hasBottomBorder: true,
    navicon: undefined,
  },
  {
    itemType: ItemType.WellControlAction,
    controlActionType: WellControlAction.Start,
    label: 'Start well',
    icon: PlayCircle,
    navicon: undefined,
  },
  {
    itemType: ItemType.WellControlAction,
    controlActionType: WellControlAction.Stop,
    label: 'Shutdown well',
    icon: StopCircle,
    navicon: undefined,
  },
  {
    itemType: ItemType.WellControlAction,
    controlActionType: WellControlAction.ClearAlarms,
    label: 'Clear Alarms',
    icon: ClearAlarm,
    navicon: undefined,
  },
  {
    itemType: ItemType.WellControlAction,
    controlActionType: WellControlAction.SetClock,
    label: 'Reset clocks',
    icon: Refresh,
    hasBottomBorder: true,
    navicon: undefined,
  },
  {
    itemType: ItemType.WellControlActionNestedList,
    controlActionType: WellControlActionNestedListType.Mode,
    nestedControlActions: modeControlActions,
    label: 'Mode',
    icon: Mode,
    hasBottomBorder: true,
    navicon: Arrowright,
  },
  {
    itemType: ItemType.NonTransactionWellControlAction,
    controlActionType: NonTransactionWellControlAction.Enable,
    label: 'Well communications',
    navicon: undefined,
  },
  {
    itemType: ItemType.WellControlAction,
    controlActionType: WellControlAction.EnablePid,
    label: 'Enable Pid',
    navicon: undefined,
  },
];

const ControlActions: React.FC<ControlActionsProps> = (props: ControlActionsProps) => {
  const assetData = useAppSelector((state) => state.assetData);
  const socketId = useAppSelector((state) => state.assetGroups?.socketId) ?? '';
  const [open, setOpen] = useState(false);
  //const [closeFunction, setCloseFunction] = useState<(() => void) | null>(null);
  const [clickedControlActionItem, setClickedControlActionItem] = useState();
  const [prevControlActionMode, setPrevControlActionMode] = useState();
  const [openWellActionModal, setOpenWellActionModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const [modalIcon, setModalIcon] = useState(React.FC);
  const [modalButtonColor, setModalButtonColor] = useState('');
  const [modeOptionView, setModeOptionView] = useState(false);
  const buttonRef = useRef(null);

  const dispatch = useAppDispatch();
  const parentToggleButton = useRef<HTMLButtonElement>(null);
  const selectedGroup = useAppSelector((State) => State.assetGroups.selectedGroup);

  // Determine supported control actions.
  const pocSupportedControlActions =
    allControlActions.filter((x) => {
      // If nested list option, check if one of the list's options are supported by current poc type before enabling it.
      // Else if not nested list option, check if the well control action is supported by the current poc type before enabling it.
      if (
        x.itemType === ItemType.WellControlActionNestedList &&
        x.nestedControlActions?.some((y) => props.supportedControlActionIds.includes(y.controlActionType))
      ) {
        return true;
      } else if (
        x.itemType === ItemType.WellControlAction &&
        props.supportedControlActionIds.includes(x.controlActionType) &&
        !props.actionsToExclude?.some(
          (action) => action.controlActionType === x.controlActionType && action.itemType === x.itemType,
        )
      ) {
        return true;
      } else if (
        x.itemType === ItemType.NonTransactionWellControlAction &&
        !props.actionsToExclude?.some(
          (action) => action.controlActionType === x.controlActionType && action.itemType === x.itemType,
        )
      ) {
        return props.canConfigWell;
      }

      return false;
    }) ?? [];

  const handleWellControlActionClick = (wellControlActionId: number, label: string): void => {
    if (!props.isWellEnabled) {
      setOpenWellActionModal(true);
      return;
    }

    if (!props.assetId || !socketId) return;
    const selectedAsset = selectedGroup?.assets.find((asset) => asset.assetId === props.assetId);
    dispatch(
      executeWellControlAction({
        assetId: props.assetId,
        controlType: wellControlActionId,
        socketId: socketId,
        controlActionName: label,
        wellName: selectedAsset?.assetName,
      }),
    );
  };

  const handleNonTransactionWellControlActionClick = (isWellEnabled: boolean): void => {
    isWellEnabled
      ? dispatch(disableWell({ assetId: props.assetId, socketId: socketId }))
      : dispatch(enableWell({ assetId: props.assetId, socketId: socketId }));
  };

  const handleItemClick = (item?: ControlActionUIItem) => {
    const controlAction = item ?? clickedControlActionItem;
    switch (controlAction?.itemType) {
      case ItemType.WellControlAction:
        handleWellControlActionClick(controlAction?.controlActionType, controlAction?.label);
        break;
      case ItemType.NonTransactionWellControlAction:
        break;
      case ItemType.WellControlActionNestedList:
        break;
    }
  };

  const handleModeMouseEnter = (target) => {
    target.target.click();
    setModeOptionView(true);
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    const relatedTarget = e.relatedTarget as HTMLElement;

    // Check if the mouse is moving to a specific element, replace 'specific-element-id' with the actual element id or class
    if (relatedTarget && relatedTarget.closest('#sub-menu-container')) {
      return;
    }

    // If not moving to the specific element, show alert and close menu
    setModeOptionView(false);
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const isEnableDisableWellItem = (item: ControlActionUIItem): boolean => {
    return (
      (item.itemType === ItemType.NonTransactionWellControlAction &&
        (item.controlActionType === NonTransactionWellControlAction.Enable ||
          item.controlActionType === NonTransactionWellControlAction.Disable)) ||
      (item.itemType === ItemType.WellControlAction && item.controlActionType === WellControlAction.EnablePid)
    );
  };

  const handleToggleWell = (e) => {
    e.currentTarget.childNodes[0].childNodes[0].click();
  };

  const renderEnableDisableWellItems = (item: ControlActionUIItem, index: number): React.JSX.Element => {
    let checked = false;
    //let confirmationMessage = '';

    if (item.controlActionType === NonTransactionWellControlAction.Enable) {
      checked = props.isWellEnabled;
      //confirmationMessage = 'Are you sure you want to enable this well?';
    } else {
      checked = !props.isWellEnabled;
      //confirmationMessage = 'Are you sure you want to disable this well?';
    }

    return (
      <div
        className='flex items-center'
        onClick={(e) => handleToggleWell(e)}
        key={`control-action-enable=-disable-${index}`}
      >
        <Switch.Group as='div' className={`icon-container has-icon`}>
          <Switch
            checked={checked}
            onChange={() => {
              handleNonTransactionWellControlActionClick(props.isWellEnabled);
            }}
            className={`custom-switch ${checked ? 'checked' : 'unchecked'}`}
            data-testid={item.label}
          >
            <span aria-hidden='true' className={`custom-switch-handle ${checked ? 'checked' : ''}`} />
          </Switch>
        </Switch.Group>
        <span data-testid='view-option-label' className='font-medium text-gray-900 option-label'>
          {item.label}
        </span>
      </div>
    );
  };

  const renderWellControlActionItem = (item: ControlActionUIItem, index: number): React.JSX.Element => {
    if (item.itemType !== ItemType.WellControlActionNestedList) {
      let parentDivClass = `control-actions-content ${item.hasBottomBorder ? 'bottom-border' : ''}`;
      let textClass = 'font-medium text-gray-900 option-label';
      if (!props.isWellEnabled && !isEnableDisableWellItem(item)) {
        parentDivClass += ' disabled pointer-events-none';
        textClass = 'font-medium option-label disabled';
      }
      return (
        <Menu.Item key={`control-action-${index}`}>
          {({ close }) => (
            <div
              className={parentDivClass}
              onClick={() => {
                let showModal = false;
                switch (item.controlActionType) {
                  case WellControlAction.Start:
                    setModalMessage('Are you sure you want to start this well?');
                    setModalTitle('Start');
                    setModalButtonText('Start');
                    setModalIcon(StartIcon);
                    setModalButtonColor('modal-start-well');
                    showModal = true;
                    break;
                  case WellControlAction.Stop:
                    setModalMessage('Are you sure you want to shutdown this well?');
                    setModalTitle('Shutdown');
                    setModalButtonText('Shutdown');
                    setModalButtonColor('modal-shutdown-well');
                    setModalIcon(Shutdown);
                    showModal = true;
                    break;
                  case WellControlAction.ClearAlarms:
                    setModalMessage('Are you sure you want to clear all alarms well?');
                    setModalTitle('Clear Alarms');
                    setModalButtonText('Clear');
                    setModalButtonColor('modal-clear-alarms');
                    setModalIcon(ClearAlarms);
                    showModal = true;
                    break;

                  case WellControlAction.SetClock:
                    setModalMessage('Are you sure you want to reset the clocks?');
                    setModalTitle('Reset Clocks');
                    setModalButtonText('Reset');
                    setModalButtonColor('modal-reset-clock');
                    setModalIcon(ResetIcons);
                    showModal = true;
                    break;
                }
                setClickedControlActionItem(item);
                close();
                parentToggleButton.current?.click();
                if (showModal) {
                  setOpen(!open);
                } else {
                  handleItemClick(item);
                }
              }}
              key={`control-action-div-${index}`}
            >
              {isEnableDisableWellItem(item) ? (
                renderEnableDisableWellItems(item, index)
              ) : (
                <>
                  <div className={`icon-container ${item.icon ? 'has-icon' : ''}`} key={`control-action-icon-${index}`}>
                    {item.icon && <img src={item.icon} alt={item.label} className='custom-icon' />}
                  </div>
                  <span data-testid='view-option-label' className={textClass} key={`view-option-label-${index}`}>
                    {item.label}
                  </span>
                </>
              )}
            </div>
          )}
        </Menu.Item>
      );
    } else {
      return renderNestedWellControlActionItems(
        item.nestedControlActions ?? [],
        item.label,
        item.icon,
        item.hasBottomBorder,
        item.navicon,
        index,
      );
    }
  };

  const renderNestedWellControlActionItems = (
    items: ControlActionUIItem[],
    label: string,
    icon?: string,
    hasBottomBorder?: boolean,
    navicon?: string,
    index?: number,
  ): React.JSX.Element => {
    let parentDivClass = `control-actions-content ${hasBottomBorder ? 'bottom-border' : ''}`;
    let textClass = 'font-medium option-label text-gray-900';

    if (!props.isWellEnabled) {
      parentDivClass += ' disabled pointer-events-none';
      textClass = 'font-medium option-label disabled';
    }
    return (
      <Menu as='div' className={parentDivClass} key={`control-actions-content-${index}`}>
        <Menu.Button
          ref={buttonRef}
          className='control-actions-nested-list-button'
          onMouseEnter={(e) => handleModeMouseEnter(e)}
          onMouseLeave={(e) => handleMouseLeave(e)}
          key={`control-actions-content-btn-${index}`}
        >
          <div className={`icon-container ${icon ? 'has-icon' : ''}`}>
            {icon && <img src={icon} alt={label} className='custom-icon' />}
          </div>
          <span data-testid='view-option-label' className={textClass}>
            {label}
          </span>
        </Menu.Button>
        {modeOptionView && (
          <Menu.Items
            id='sub-menu-container'
            style={{ [props.modeOptionViewPosition]: '196px', top: '235px' }}
            className='absolute z-10 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus-outline-none'
            onMouseLeave={() => {
              setModeOptionView(false);
              if (buttonRef.current) {
                buttonRef.current.click();
              }
            }}
          >
            {items.map((option, index) => {
              return (
                <Menu.Item key={`menu-item-control-action-${index}`}>
                  {({ close }) => (
                    <div
                      className={`control-actions-content ${option.hasBottomBorder ? 'bottom-border' : ''}`}
                      onClick={() => {
                        setClickedControlActionItem(option);
                        close();
                        handleItemClick(option);
                        setPrevControlActionMode(option);
                        dispatch(setPreviousControlMode(prevControlActionMode));
                        parentToggleButton.current?.click();
                      }}
                      key={`control-actions-content-${index}`}
                    >
                      <div
                        className={`icon-container ${option.icon ? 'has-icon' : ''}`}
                        key={`icon-container-${index}`}
                      >
                        {option.icon && <img src={option.icon} alt={option.label} className='custom-icon' />}
                      </div>
                      <span data-testid='view-option-label' className='font-medium text-gray-900 option-label'>
                        {option.label}
                      </span>
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </Menu.Items>
        )}
        <div className={`icon-container ${icon ? 'has-icon' : ''}`}>
          {navicon && <img src={navicon} className='custom-icon' />}
        </div>
      </Menu>
    );
  };

  const renderWellControlActionItems = (items: ControlActionUIItem[]): React.JSX.Element => {
    return (
      <>
        {/* Show toast when control action is sent */}
        {assetData.executeControlActionMessage && (
          <SuccessfullToast
            isShowEnabled={assetData.executeControlActionShowSuccess}
            message={assetData.executeControlActionMessage}
            callback={() => {
              dispatch(clearShowMessageToast('success'));
            }}
          />
        )}
        {/* Show toast when control action could not be sent successfully */}
        {assetData.wellControlActionsError && (
          <ErrorToast
            isShowEnabled={assetData.executeControlActionShowFailure}
            message={assetData.executeControlActionErrorMessage}
            callback={() => {
              dispatch(clearShowMessageToast('failure'));
            }}
          />
        )}
        {assetData.executeControlActionOnReturnError && (
          <ErrorToast
            isShowEnabled={assetData.executeControlActionOnReturnError}
            message={assetData.executeControlActionOnReturnMessage}
            callback={() => {
              dispatch(clearShowMessageToast('onReturnError'));
            }}
          />
        )}

        {/* Show toast when control action execution was successful (returned from web socket) */}
        {assetData.executeControlActionOnReturnSuccessMessage && (
          <SuccessfullToast
            isShowEnabled={assetData.executeControlActionOnReturnSuccess}
            message={assetData.executeControlActionOnReturnSuccessMessage}
            callback={() => {
              dispatch(clearShowMessageToast('onReturnSuccess'));
            }}
          />
        )}
        {open && (
          <Modal
            message={modalMessage}
            title={modalTitle}
            Icon={modalIcon}
            buttonText={modalButtonText}
            open={open}
            setOpen={setOpen}
            callbackOnConfirm={() => {
              handleItemClick();
            }}
            buttonColor={modalButtonColor}
          />
        )}
        {openWellActionModal && (
          <Modal
            message={'This well is disabled'}
            buttonText='Ok'
            open={openWellActionModal}
            setOpen={setOpenWellActionModal}
            callbackOnConfirm={() => setOpenWellActionModal(false)}
            title={'Well Control Action'}
            hideCancelButton={true}
          />
        )}
        <Menu as='div' className='toggle-dropdown-container'>
          {() => (
            <>
              <div>
                <Menu.Button ref={parentToggleButton}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {props.buttonContent}
                  </div>
                </Menu.Button>
              </div>

              <Transition
                as={React.Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus-outline-none'>
                  <div className='dropdown-label-container'>
                    <p className='option-label'>{props.label}</p>
                  </div>
                  {items.map((option, index) => {
                    return renderWellControlActionItem(option, index + 10);
                  })}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </>
    );
  };

  return renderWellControlActionItems(pocSupportedControlActions);
};

export default ControlActions;
